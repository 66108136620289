@import "node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "Bebas Neue";
  src: url("/src/fonts/BebasNeue-Regular.woff2") format("woff2"), url("/src/fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*---------------------------------------
  CUSTOM PROPERTIES ( VARIABLES )             
-----------------------------------------*/
:root {
  --white-color: #ffffff;
  --primary-color: #00deff;
  --secondary-color: #74a2b1;
  --section-bg-color: #f0f8ff;
  --custom-btn-bg-color: #74a2b1;
  --custom-btn-bg-hover-color: #597e8a;
  --dark-color: #140e25;
  --p-color: #fff;
  --border-color: #74a2b1;
  --link-hover-color: #74a2b1;

  --body-font-family: "Outfit", sans-serif;

  --h1-font-size: 2.75rem;
  --h2-font-size: 2.75rem;
  --h2-font-size-small: 1.5rem;
  --h3-font-size: 1.5rem;
  --h4-font-size: 28px;
  --h5-font-size: 24px;
  --h6-font-size: 22px;
  --p-font-size: 18px;
  --btn-font-size: 14px;
  --copyright-font-size: 16px;

  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;

  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
}

body {
  background-color: var(--dark-color);
  font-family: var(--body-font-family);
  color: var(--white-color);
}

/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue";
  font-weight: normal;
  font-style: normal;
  color: var(--white-color);
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
  &.small {
    font-size: var(--h2-font-size-small);
  }
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

ul li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

a,
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  display: inline-block;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

.link-fx-1 {
  color: var(--white-color);
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 0 6px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.link-fx-1:hover {
  color: var(--link-hover-color);
}

.link-fx-1:hover::before {
  transform: translateX(17px) scaleX(0);
  transition: transform 0.2s;
}

.link-fx-1:hover .icon circle {
  stroke-dashoffset: 200;
  transition: stroke-dashoffset 0.2s 0.1s;
}

.link-fx-1:hover .icon line {
  transform: rotate(-180deg);
}

.link-fx-1:hover .icon line:last-child {
  transform: rotate(180deg);
}

.link-fx-1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transform-origin: right center;
  transition: transform 0.2s 0.1s;
}

.link-fx-1 .icon {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(100%) rotate(90deg);
  font-size: 32px;
}

.icon {
  --size: 1em;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.link-fx-1 .icon circle {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  transition: stroke-dashoffset 0.2s;
}

.link-fx-1 .icon line {
  transition: transform 0.4s;
  transform-origin: 13px 15px;
}

.link-fx-1 .icon line:last-child {
  transform-origin: 19px 15px;
}

/*---------------------------------------
  NAVIGATION              
-----------------------------------------*/
.sticky-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin-top: 51px;
}

.sticky-wrapper.is-sticky .navbar {
  background-color: var(--dark-color);
}

.navbar {
  background: transparent;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-family: "Bebas Neue";
  font-weight: normal;
  font-style: normal;
  color: var(--dark-color);
  font-size: 1.5em;

  & li {
    & span {
      color: var(--dark-color);
      font-family: "Bebas Neue";
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
    }
  }
}

.navbar-brand,
.navbar-brand:hover {
  color: var(--white-color);
  font-size: var(--h5-font-size);
  font-weight: var(--font-weight-bold);
}

.navbar-expand-lg .navbar-nav .nav-link {
  border-radius: var(--border-radius-large);
  margin: 8px 10px;
  padding: 0;
}

.navbar-nav .nav-link {
  display: inline-block;
  position: relative;
  padding: 1em 0;
  font-size: 1.5em;
  color: var(--dark-color);
  font-size: 1.5em;
  font-family: "Bebas Neue";
  font-weight: normal;
  font-style: normal;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--secondary-color);
}

.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  height: 35px;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--dark-color);
  transition: background 10ms 300ms ease;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--dark-color);
  width: 30px;
  height: 2px;
  content: "";
}

.navbar-toggler .navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon::after {
  top: 8px;
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  #navbarNav {
    background-color: rgba(0, 0, 0, 0.9);
    margin: 0.5em 6vw 2em;

    & li {
      & span {
        display: none;
      }
    }
  }
  .navbar-nav .nav-link {
    color: var(--light-color);
  }
}

/*---------------------------------------
  MAIN             
-----------------------------------------*/
.masthead {
  img {
    min-height: 20em;
    object-fit: cover;
    object-position: center;
  }
}
.intro {
  .logo {
    width: 30vw;
    min-width: 200px;
  }
}

.social {
  width: 400px;

  & a:hover {
    opacity: 0.6;
  }
}
#biography {
  text-align: center;

  & .innner {
    max-width: 80vw;
  }
  & p {
    font-family: "Bebas Neue";
    font-weight: normal;
    font-style: normal;
  }
  & img {
    width: 250px;
    height: 250px;
    border: 0.4em solid;
    object-fit: cover;
  }
}
.img-limiter-1 {
  object-fit: cover;
  object-position: center;
  max-height: 500px;
}
.img-limiter-2 {
  object-fit: cover;
  object-position: top;
  max-height: 50vw;
}
.img-bg-white {
  background-color: #fff;
  width: 100%;
  object-fit: contain;
  height: 180px;
}
.img-bg-black {
  background-color: #000;
  width: 100%;
  object-fit: contain;
  height: 180px;
}

@media (max-width: 450px) {
  .social {
    width: 100%;
  }
  h1 {
    font-size: 2.2rem;
  }
}
